<template>
  <div class="zcxy">
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="comment_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
        <template slot="stars" slot-scope="text, record">
          <a-rate :defaultValue="text" disabled/>
        </template>
        <template slot="serial_number" slot-scope="text, record">
          <a @click="showEditModal(record)">{{text}}</a>
        </template>
        <template slot="action" slot-scope="text,record">
          <a v-if="canRead" href="javascript:;" @click="showEditModal(record)">订单详情</a>
          <a-divider v-if="canRead" type="vertical" />
          <a v-if="canDelete" href="javascript:;" @click="deleteItem(record)">删除</a>
        </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '下单用户', width: '150px', dataIndex: 'student_name', key: '1' },
  { title: '订单编号', width: '170px', dataIndex: 'serial_number', key: 'name',scopedSlots: { customRender: 'serial_number' }, },
  { title: '评论内容', dataIndex: 'comment_content', key: '3' ,ellipsis:true},
  { title: '商品星级', dataIndex: 'comment_stars', key: '4', scopedSlots: { customRender: 'stars' } },
  { title: '评论时间', dataIndex: 'created_at', key: '5' },
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' }},
]


import C_ITEMS from '@/utils/items'
import editModal from './editModal'
import authority from '@/common/mixins/authority'
import tableMixins from '@/common/mixins/table'
export default {
  name: 'gjjl',
  provide() {
    return {
      parent: this
    }
  },

  components: {
    editModal,
  },

  data() {
    return {
      loading: false,
      pageSizeOptions: ['10', '20', '30', '40', '100'],
      current: 1,
      pageParams: {
        currentPage: 1,
        pageCount: 50,
        perPage: 10,
        totalCount: 0
      },
      columns,
      searchParams: {
        "page": 1,
        "per-page": 10,
        "search": {},
        "sort": ''
      },
      list: [],
      current: 1,

      productStatus: C_ITEMS.productStatus,
      productCom: C_ITEMS.productCom,

      searchData: {},

      visible: false,
      modalData: {},
      editVisible: false,
      authType:['market','store'],
    }
  },
  mixins: [ authority, tableMixins ],

  methods: {
    closeSearch() {
      this.visible = false
    },
    async getList() {
      if(!this.canIndex){
          this.$message.warning('您暂无权限!!')
          return false
      }
      this.loading = true
      let obj = {}
      let { searchData } = this
      if (searchData) {
        for (let k in searchData) {
          obj[k] = searchData[k]
        }
      }
      this.searchParams.search = obj
      let res = await this.$store.dispatch('storeCommentAction', { data: this.searchParams })
      this.list = res.items
      this.pageParams = res._meta
      this.loading = false
    },
    showEditModal(item) {
      this.modalData = item
      this.editVisible = true
    },
    hideEditModal(type) {
      if (type === 1) {
        this.getList()
      }
      this.editVisible = false
    },
    
    deleteItem(item) {
      let that = this
      this.$confirm({
        title: `您确定要删除评论吗？`,
        okText: '确定',
        okType: 'danger',
        cancelText: '取消',
        onOk() {
          return new Promise(async (resolve, reject) => {
            let res = await that.$store.dispatch('storeOrderDeleteAction', { data: { comment_id: item.comment_id } })
            if (res) {
              that.getList()
              resolve(res)
            }
          }).catch(error => console.log(error))
        }
      })
    },
  }
}
</script>
