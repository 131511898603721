<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="525px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <Distpicker v-if="item.type === 'distpicker'" @change="changeDist"/>
        <LMap v-if="item.type === 'map'" :valueData="mapValue"/>
        <a-rate v-if="item.type === 'rate'" allowHalf v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <div v-if="item.type === 'upload2'" style="display: inline-block; position: relative;">
          <a-button type="dashed" icon="file-image" @click="showImagesModal(item.name)">选择图片</a-button>
          <div style="display: flex; flex-wrap: wrap;">
            <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="index">
              <LImg :src="img" style="width: 100px; height: 100px;"/>
              <div class="close" @click="closeImg2($event, index)">&times;</div>
            </div>
          </div>
        </div> 
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-if="item.type === 'textarea'" v-decorator="[item.name, { rules: item.rules }]"/>
        <l-editor v-else-if="item.type === 'textarea2'" name="product_desc" :data="product_desc"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'cat_image',
    label: '分类图片',
    type: 'upload',
    rules: [{ required: false, message: '请输入分类图片!' }]
  },
  {
    name: 'cat_name',
    label: '分类名称',
    type: 'input',
    rules: [{ required: true, message: '请输入分类名称!' }]
  },
  {
    name: 'cat_descs',
    label: '分类描述',
    type: 'textarea',
    rules: [{ required: false, message: '请输入分类描述!' }]
  },
]
import C_ITEMS from '@/utils/items'
import moment from 'moment'
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'
import Distpicker from 'vue-distpicker'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
    Distpicker,
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增分类',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        classCategorys: [],
        types: C_ITEMS.studioType
      },
      
      imageUrl: '',
      imageUrls: [],
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
      product_desc: '',
      editor: {},
      distData: {},
      mapValue: {},
      mapData: {}
    }
  },

  async created() {
    this.visible = true
    await this.$nextTick()
    await this.getClassCategory()
    if (this.item) {
      this.ModalTitle = '编辑分类'
      let item = await this.getDetail(this.item.cat_id)
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.imageUrl = item['cat_image']
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getClassCategory() {
      let res = await this.$store.dispatch('searchBelongStudioAction', {})
      this.seleteItems.classCategorys = res.data
    },
    async getDetail(cat_id) {
      let res = await this.$store.dispatch('storeProductCategoryDetailAction', {data: {cat_id}})
      return res.data
    },
    changeEditor(key, value) {
      this.editor[key] = value
    },
    changeDist(data) {
      this.distData = {
        province: data[0].name,
        city: data[1].name,
        district: data[2].name
      }
    },
    changeMap(data) {
      console.log(data)
      this.mapData = data
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.cat_id = item.cat_id
          }
          params.cat_image = this.imageUrl
          params.product_desc = this.editor.product_desc
          let res = await this.$store.dispatch(item ? 'storeProductCategoryUpdateAction' : 'storeProductCategoryAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'cat_images') {
          this.imageUrls.push(image.path)
        } else {
          this.imageUrl = image.path
        }
      }
      this.imagesVisible = false
    },
  }
}
</script>
