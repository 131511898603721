<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>

        <a-radio-group v-else-if="item.type === 'radio'" v-decorator="[item.name, { rules: item.rules }]">
          <a-radio v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-radio>
        </a-radio-group>
        
        <a-textarea v-else-if="item.type === 'textarea'" :rows="4"
           v-decorator="[item.name, { rules: item.rules }]"/>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'coupon_title',
    label: '优惠券名称',
    type: 'input',
    rules: [{ required: true, message: '请输入优惠券名称!' }]
  },
  {
    name: 'coupon_type',
    label: '优惠券类型',
    type: 'radio',
    rules: [{ required: true, message: '请选择优惠券类型!' }],
    items: {
      data: 'couponTypes',
      label: 'label',
      value: 'value'
    }
  },
  {
    name: 'coupon_req_amount',
    label: '满多少',
    type: 'input',
    rules: [{ required: true, message: '请输入满多少!' }]
  },
  {
    name: 'coupon_dis_amount',
    label: '减多少',
    type: 'input',
    rules: [{ required: true, message: '请输入减多少!' }]
  },
  {
    name: 'coupon_limit',
    label: '发放数',
    type: 'input',
    rules: [{ required: true, message: '请输入发放数!' }]
  },
  {
    name: 'coupon_start_time',
    label: '开始有效期',
    type: 'date',
    rules: [{ required: true, message: '请选择开始有效期!' }]
  },
  {
    name: 'coupon_end_time',
    label: '结束时间',
    type: 'date',
    rules: [{ required: true, message: '请选择结束时间!' }]
  },
]
import C_ITEMS from '@/utils/items'
import moment from 'moment'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增商品优惠券',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        couponTypes: C_ITEMS.couponTypes
      }
    }
  },


  async created() {
    this.visible = true
    await this.$nextTick()
    if (this.item) {
      this.ModalTitle = '编辑商品优惠券'
      let {item} = this
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.coupon_id = item.coupon_id
          }
          params.coupon_start_time = moment(params.coupon_start_time).format('YYYY-MM-DD')
          params.coupon_end_time = moment(params.coupon_end_time).format('YYYY-MM-DD')
          let res = await this.$store.dispatch(item ? 'storeCouponUpdateAction' : 'storeCouponAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    }
  }
}
</script>
