<template>
  <div class="zcxy">
    <div class="clearfix table-tools">
      <div class="buttons">
        <a-form layout='inline'>
          <a-form-item>
            <a-button icon="plus" type="primary" @click="showEditModal()">新增兑换</a-button>
          </a-form-item>
        </a-form>
      </div>
      <div class="search">
        <a-form layout='inline' @submit="searchList">
          <a-form-item>
            <a-tooltip placement="topLeft" >
              <template slot="title">
                <span>商品名称</span>
              </template>
              <a-input allowClear v-model.trim="searchParams.product_name" placeholder="商品名称" style="width: 200px"/>
            </a-tooltip>
          </a-form-item>
          <a-form-item>
            <a-button type="primary" html-type="submit" @click="searchList">搜索</a-button>
          </a-form-item>
        </a-form>
      </div>
    </div>
    <div class="table">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-table size="middle" :pagination="false" :bordered='false' rowKey="product_id"
        :columns="columns" :dataSource="list">
          <template slot="index" slot-scope="text, record , index">
            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
          </template>
          <template slot="image" slot-scope="text, record">
            <LImg :src="text" style="width: 44px; height: 44px;"/>
          </template>
          <template slot="action" slot-scope="text,record">
            <a href="javascript:;" @click="deleteItem(record)">删除</a>
          </template>
      </a-table>
    </div>
    <div class="page">
      <a-pagination
        :pageSizeOptions="pageSizeOptions"
        :total="pageParams.totalCount"
        showSizeChanger
        :pageSize="pageParams.perPage"
        v-model="current"
        @change="changePage"
        @showSizeChange="onShowSizeChange"
          :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
      >
          <template slot='buildOptionText' slot-scope='props'>
            <span v-if="props.value!=='100'">{{props.value}}条/页</span>
            <span v-if="props.value==='100'">100条/页</span>
          </template>
        </a-pagination>
    </div>
    <editModal v-if="editVisible" :item="modalData"/>
  </div>
</template>

<script>
const columns = [
  { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
  { title: '商品图片', dataIndex: 'image', key: 'image', scopedSlots: { customRender: 'image' }},
  { title: '商品名称', dataIndex: 'title', key: 'title' },
  { title: '兑换积分', dataIndex: 'product_credits', key: 'product_credits' },
  { title: '商品描述', width:'500px', dataIndex: 'desc', key: 'desc' },
  { title: '操作', key: 'operation', align: 'right', scopedSlots: { customRender: 'action' }},
]
    import editModal from './editModal'
    import tableMixins from '@/common/mixins/table'
    export default {
        name:'credit',
        data() {
            return {
                columns,
                list: [],
                loading:false,
                editVisible:false,
            }
        },
        components: {
          editModal,
        },
        mixins: [ tableMixins ],
        methods: {
            async getList() {
                this.loading = true
                let res = await this.$store.dispatch('storeCreditAction', { data: this.searchParams })
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            showEditModal(item) {
              this.modalData = item
              this.editVisible = true
            },
            hideEditModal(type) {
              if (type === 1) {
                this.getList()
              }
              this.editVisible = false
            },
            deleteItem(item) {
              let that = this
              this.$confirm({
                title: `您确定要删除该商品？`,
                okText: '确定',
                okType: 'danger',
                cancelText: '取消',
                onOk() {
                  return new Promise(async (resolve, reject) => {
                    let res = await that.$store.dispatch('storeCreditDeleteAction', { data: { product_id: item.product_id } })
                    if (res) {
                      that.$message.success('操作成功！！')
                      that.getList()
                      resolve(res)
                    }
                  }).catch(error => console.log(error))
                }
              })
            },
        },
    }
</script>

<style lang="scss" scoped>

</style>