<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="400px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form" layout="vertical">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item v-for="(item, index) of formData" :key="index" :label="item.label">
        <a-input-number style="width:100%" v-if="item.type === 'number'" :min="0" v-decorator="[item.name, { rules: item.rules }]"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]" showSearch @search="handleSearch" :filterOption="filterOption">
          <a-select-option v-for="(d, index) of product_list" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'product_id',
    label: '选择商品',
    type: 'select',
    rules: [{ required: true, message: '请选择商品!' }],
    items: {
      data: 'product_list',
      label: 'product_name',
      value: 'product_id'
    }
  },
  {
    name: 'product_credits',
    label: '兑换积分',
    type: 'number',
    rules: [{ required: true, message: '请输入兑换积分!' }]
  }
]
  export default {
    name:'editModal',
    data() {
      return {
        formData,
        loading: false,
        visible: false,
        confirmLoading: false,
        ModalTitle:'新增兑换',
        product_list:'',
        form: this.$form.createForm(this),
      }
    },
    inject: ['parent'],
    created () {
      this.visible = true
      this.getProduct()
    },
    methods: {
      filterOption(input, option) {
        return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      },
      getProduct(obj) {
        this.$store.dispatch('storeProductAction',{data:{search:obj}})
        .then(res=>{
          this.product_list = res.items
        })
      },
      handleSearch(val){
        this.getProduct({product_name:val})
      },
      async handleOk(){
        let params = await this.form.validateFields()
        this.confirmLoading = true
        await this.$store.dispatch('storeCreditAddAction',{data:params})
        .then(res=>{
          this.$message.success('操作成功！！')
          this.parent.hideEditModal(1)
        })
        .catch(err=>{
          this.confirmLoading = false
        })
      },
      handleCancel(){
        this.parent.hideEditModal()
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>