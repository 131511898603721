<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="关闭" okText="确认" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <div class="form-list">
      <div class="form-list-item"><strong>订单状态：</strong>{{ detail.status_name }}</div>
      <div class="form-list-item"><strong>订单金额：</strong><span class="red">¥{{ detail.order_amount }}</span></div>
      <div class="form-list-item"><strong>下单时间：</strong>{{ detail.order_time }}</div>
      <div class="form-list-item"><strong>订单优惠：</strong><span class="red">¥0</span></div>
      <div class="form-list-item"><strong>订单编号：</strong>{{ detail.serial_number }}</div>
      <div class="form-list-item"><strong>订单运费：</strong><span class="red">¥{{ detail.order_yunfei }}</span></div>
      <!-- <div class="form-list-item"><strong>支付编号：</strong></div> -->
      <div class="form-list-item"><strong>收货时间：</strong>{{ detail.receive_time }}</div>
    </div>
    <a-table style="margin: 15px 0;" size="small" :pagination="false" :bordered='false' rowKey="detail_id"
      :columns="columns" :dataSource="detail.orders">
      <template slot="total" slot-scope="text, record">
        <span class="red">{{ record.original_amount * record.product_count }}</span>
      </template>
    </a-table>
    <div class="form-list" v-if="detail.address">
      <div class="form-list-item"><strong>姓名：</strong>{{ detail.address.user_name }}</div>
      <div class="form-list-item"><strong>电话：</strong>{{ detail.address.tel_number }}</div>
      <div class="form-list-item line-block"><strong>地址：</strong>
      <span v-if="detail.address">{{ detail.address.address }}</span></div>
      <div class="form-list-item"><strong>邮编：{{detail.postal_code}}</strong></div>
      <div class="form-list-item"><strong>配送：</strong>随时可以</div>
      <div class="form-list-item line-block"><strong>备注：</strong>{{detail.remark}}</div>
    </div>
    <a-divider />
    <!-- <a-form :form="form" layout='inline'>
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]" style="width: 200px"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择" style="width: 200px"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, k) in seleteItems[item.items.data]" :key="k" :value="k">{{ d }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form> -->
  </a-modal>
</template>
<style lang="scss" scoped>
.form-list {
  display: flex;
  flex-wrap: wrap;
  .form-list-item {
    width: 50%;
    margin: 3px 0;
    &.line-block {
      width: 100%;
    }
    strong {
      font-weight: 500;
      color: #888;
    }
  }
}
.red {
  color: #f00;
}
</style>

<script>
const columns = [
  { title: '#', width:'55px', customRender:(text,record,index)=>`${index+1}`, key: 'index'},
  { title: '商品名称', dataIndex: 'product_name', key: 'name',scopedSlots: { customRender: 'image' }, },
  // { title: '商品规格', width: '150px', dataIndex: 'orders', key: '1', scopedSlots: { customRender: 'orders' } },
  { title: '下单单价', dataIndex: 'original_amount', key: '2' },
  { title: '下单数量', dataIndex: 'product_count', key: '3' },
  { title: '总计', dataIndex: 'total', key: '4', scopedSlots: { customRender: 'total' } },
]
const formData = [
  {
    name: 'express_code',
    label: '快递单号',
    type: 'input',
    rules: [{ required: true, message: '请输入快递单号!' }]
  },
  {
    name: 'express_com',
    label: '快递公司',
    type: 'select',
    rules: [{ required: true, message: '请选择快递公司!' }],
    items: {
      data: 'expressCodes',
      label: '',
      value: ''
    }
  },
]
import C_ITEMS from '@/utils/items'
import moment from 'moment'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '订单详情',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        expressCodes: [],
      },

      columns,
      list: [],
      detail: {},
    }
  },

  async created() {
    this.visible = true
    this.getDetail()
    this.getExpressCode()
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    async getExpressCode() {
      let res = await this.$store.dispatch('storeOrderExpressCodeAction', {})
      this.seleteItems.expressCodes = res.data
    },
    async getDetail() {
      let { order_id } = this.item
      let res = await this.$store.dispatch('storeOrderDetailAction', {data: {order_id}})
      this.detail = res.data
    },
    async handleOk() {
      this.confirmLoading=true;
      this.parent.hideEditModal(1)
      // try {
      //   const { item, form } = this
      //   let params = await form.validateFields()
      //   if (params) {
      //     if (item) {
      //       params.order_id = item.order_id
      //     }
      //     let res = await this.$store.dispatch('storeOrderExpressAction', { data: params })
      //     if(res) {
      //       form.resetFields()
      //       this.parent.hideEditModal(1)
      //     }
      //   }
      // } catch {
      // }
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
  }
}
</script>
