<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="700px"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'table'">
          <a-table size="small" :pagination="false" :bordered='false' rowKey="det_id"
            :columns="columns" :dataSource="dets">
            <template slot="det_name" slot-scope="text,record">
              <a-input v-model="record.det_name" />
            </template>
            <template slot="det_sort" slot-scope="text,record">
              <a-input v-model="record.det_sort" />
            </template>
            <template slot="action" slot-scope="text,record,index">
              <a href="javascript:;" @click="deleteItem(index)">删除</a>
            </template>
            <template slot="footer" slot-scope="currentPageData">
              <div style="text-align: center"><a-button icon="plus" @click="addItem">添加</a-button></div>
            </template>
          </a-table>
        </div>
      </a-form-item>
    </a-form>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'spec_name',
    label: '规格名称',
    type: 'input',
    rules: [{ required: true, message: '请输入规格名称!' }]
  },
  {
    name: 'dets',
    label: '型号',
    type: 'table',
    rules: [{ required: true, message: '请输入型号!' }]
  },
]
const columns = [
  { title: '型号名称', dataIndex: 'det_name', key: 'name', scopedSlots: { customRender: 'det_name' }, },
  { title: '排序', dataIndex: 'det_sort', key: '4', scopedSlots: { customRender: 'det_sort' } },
  { title: '操作', width: 46, key: '5', scopedSlots: { customRender: 'action' } },
]
import C_ITEMS from '@/utils/items'
export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },

  data() {
    return {
      labelCol: { span: 5 },
      wrapperCol: { span: 15 },
      ModalTitle: '新增规格',
      visible: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        couponTypes: C_ITEMS.couponTypes
      },
      columns,
      dets: [{det_name: '', det_sort: ''}]
    }
  },
  

  async created() {
    this.visible = true
    await this.$nextTick()
    if (this.item) {
      this.ModalTitle = '编辑规格'
      let {item} = this
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.form.setFieldsValue(formFields)
    }
  },

  beforeDestroy() {
    this.visible = false
  },

  methods: {
    addItem() {
      this.dets.push({det_name: '', det_sort: ''})
    },
    deleteItem(index) {
      this.dets.splice(index, 1)
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.spec_id = item.spec_id
          }
          params.spec_dets = this.dets
          let res = await this.$store.dispatch(item ? 'storeProductSpecUpdateAction' : 'storeProductSpecAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch {
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    }
  }
}
</script>
