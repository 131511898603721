<template>
  <a-modal :title="ModalTitle" v-model="visible" :confirmLoading="confirmLoading"
    cancelText="取消" okText="保存" width="42.5%"
    @ok="handleOk" @cancel="handleCancel">
    <a-form :form="form">
      <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
      <a-form-item v-for="(item, index) of formData" :key="index" 
        :label="item.label" :label-col="labelCol" :wrapper-col="wrapperCol">
        <a-input v-if="item.type === 'input'" v-decorator="[item.name, { rules: item.rules }]"/>
        <Distpicker v-if="item.type === 'distpicker'" @change="changeDist"/>
        <LMap v-if="item.type === 'map'" :valueData="mapValue"/>
        <a-rate v-if="item.type === 'rate'" allowHalf v-decorator="[item.name, { rules: item.rules }]"/>
        <div v-if="item.type === 'upload'" style="display: inline-block; position: relative;" @click="showImagesModal(item.name)">
          <div v-if="imageUrl">
            <LImg :src="imageUrl" style="width: 100px; height: 100px;"/>
            <div class="close" @click="closeImg($event)">&times;</div>
          </div>
          <a-button v-else type="dashed" icon="file-image">选择图片</a-button>
        </div>
        <div v-if="item.type === 'upload2'" style="display: inline-block; position: relative;">
          <a-button type="dashed" icon="file-image" @click="showImagesModal(item.name)">选择图片</a-button>
          <div style="display: flex; flex-wrap: wrap;">
            <div style="position: relative; margin: 0 10px 10px 0;" v-for="(img, index) of imageUrls" :key="index">
              <LImg :src="img" style="width: 100px; height: 100px;"/>
              <div class="close" @click="closeImg2($event, index)">&times;</div>
            </div>
          </div>
        </div> 
        <div v-if="item.type === 'table'">
          <!-- <a-input v-decorator="[item.name, { rules: item.rules }]" style="width: 250px;"/> -->
          <div v-for="(item,index) in skuList" :key="index" class="sku-warp">
            <a-form>
              <a-form-item label="规格名" :label-col="{span:2}" :wrapper-col="wrapperCol">
                <a-select placeholder="请选择" :value="item.id" @change="(e,node)=>handleSkuSelect(e,node,item,index)" style='width:150px'>
                  <a-select-option v-for="(d, ind) of sku" :key="ind" :value="d.id" :disabled="d.disabled">{{ d.name }}</a-select-option>
                </a-select>
              </a-form-item>
              <a-form-item label="规格值" :label-col="{span:2}" :wrapper-col="{span:22}">
                <a-input v-if="item.arr && item.arr.length > 0" v-for="(it,i) in item.arr" @blur="handleSkuBlur" @change="e=>handleSkuValue(e,item.arr,i,item.label)" :value="it[item.label]" :key="i" style='width:150px;margin:5px'>
                  <a-icon @click="delSkuValue(item.arr,i)" slot="addonAfter" type="delete" />
                </a-input>
                <a-button @click="addSkuValue(item)" style="margin-left:5px" size="small" type="primary">添加规格值</a-button>
              </a-form-item>
            </a-form>
            <div v-if="skuList.length > 1" class="sku-del">
              <a-icon @click="delSku(item,index)" type="delete" />
            </div>
          </div>
          <div>
            <a-button @click="addSku" size="small" type="primary">添加规格项目</a-button>
          </div>
          <a-table size="small" :pagination="false" :bordered='false' rowKey="det_id"
            :columns="columnsF" :dataSource="specs">
            <template slot="detName" slot-scope="text,record">
              <a-input v-model="record.det_name" />
            </template>
            <template slot="marketPrice" slot-scope="text,record">
              <a-input v-model="record.market_price" />
            </template>
            <template slot="salePrice" slot-scope="text,record">
              <a-input v-model="record.sale_price" />
            </template>
            <template slot="instock" slot-scope="text,record">
              <a-input v-model="record.instock" />
            </template>
            <!-- <template slot="action" slot-scope="text,record,index">
              <a href="javascript:;" @click="deleteItem(record, index)">删除</a>
            </template>
            <template slot="footer" slot-scope="currentPageData">
              <div style="text-align: center"><a-button icon="plus" @click="addItem">添加</a-button></div>
            </template> -->
          </a-table>
        </div>
        <a-date-picker v-if="item.type === 'date'" style="width: 100%"  v-decorator="[item.name, { rules: item.rules }]" format="YYYY-MM-DD"/>
        <a-select v-else-if="item.type === 'select'" placeholder="请选择"
           v-decorator="[item.name, { rules: item.rules }]">
          <a-select-option v-for="(d, index) of seleteItems[item.items.data]" :key="index" :value="d[item.items.value]">{{ d[item.items.label] }}</a-select-option>
        </a-select>
        <a-textarea v-if="item.type === 'textarea'" v-decorator="[item.name, { rules: item.rules }]"/>
        <l-editor v-else-if="item.type === 'textarea2'" name="product_desc" :data="product_desc"/>
      </a-form-item>
    </a-form>
    <ImageModal v-if="imagesVisible"/>
  </a-modal>
</template>

<script>
const formData = [
  {
    name: 'product_image',
    label: '商品图 (尺寸 240*240)',
    type: 'upload',
    rules: [{ required: true, message: '请输入商品图!' }]
  },
  {
    name: 'product_name',
    label: '商品名称',
    type: 'input',
    rules: [{ required: true, message: '请输入商品名称!' }]
  },
  {
    name: 'product_subtitle',
    label: '商品简介',
    type: 'textarea',
    rules: [{ required: true, message: '请输入商品简介!' }]
  },
  {
    name: 'product_code',
    label: '商品编号',
    type: 'input',
    rules: []
  },
  {
    name: 'product_cat',
    label: '商品分类',
    type: 'select',
    rules: [{ required: true, message: '请输入商品分类!' }],
    items: {
      data: 'productCategorys',
      label: 'cat_name',
      value: 'cat_id'
    }
  },
  {
    name: 'sale_price',
    label: '销售价格￥',
    type: 'input',
    rules: [{ required: true, message: '请输入销售价格!' }]
  },
  {
    name: 'market_price',
    label: '市场价￥',
    type: 'input',
    rules: [{ required: true, message: '请输入市场价!' }]
  },
  {
    name: 'product_expfee',
    label: '快递费用',
    type: 'input',
    rules: [{ required: true, message: '请输入商品快递费用!' }]
  },
  // {
  //   name: 'product_com',
  //   label: '是否参与红人推广计划',
  //   type: 'select',
  //   rules: [],
  //   items: {
  //     data: 'types',
  //     label: 'label',
  //     value: 'value'
  //   }
  // },
  {
    name: 'product_instocks',
    label: '商品库存',
    type: 'input',
    rules: [{ required: true, message: '请输入商品库存!' }]
  },
  {
    name: 'spec',
    label: '商品规格',
    type: 'table',
    rules: []
  },
  {
    name: 'product_images',
    label: '商品图片介绍',
    type: 'upload2',
    rules: [{ required: true, message: '请输入商品图片介绍!' }]
  },
  {
    name: 'product_desc',
    label: '商品描述',
    type: 'textarea2',
    rules: [{ required: true, message: '请输入商品描述!' }]
  },
]

const columns = [
  { title: '型号名称', dataIndex: 'det_name', key: 'name', scopedSlots: { customRender: 'detName' }, },
  { title: '市场价格', dataIndex: 'market_price', key: '2', scopedSlots: { customRender: 'marketPrice' } },
  { title: '销售价格', dataIndex: 'sale_price', key: '3', scopedSlots: { customRender: 'salePrice' } },
  { title: '库存', dataIndex: 'instock', key: '4', scopedSlots: { customRender: 'instock' } },
  // { title: '操作', width: 80, key: '5', scopedSlots: { customRender: 'action' } },
]

let sku = [
  {
    id:1,
    index:0,
    name:'颜色',
    label:"color",
    disabled:false,
    arr:[]
  },
  {
    id:2,
    index:1,
    name:'尺寸',
    label:"size",
    disabled:false,
    arr:[]
  },
  {
    id:3,
    index:2,
    name:'大小',
    label:"big",
    disabled:false,
    arr:[]
  },
  {
    id:4,
    index:3,
    name:'重量',
    label:"weight",
    disabled:false,
    arr:[]
  },
]

import C_ITEMS from '@/utils/items'
import moment from 'moment'
import url from '@/utils/URL'
import ImageModal from '@/views/modal/images'
import Distpicker from 'vue-distpicker'

export default {
  name: 'EditModal',
  inject: ['parent'],
  props: {
    item: Object
  },
  provide() {
    return {
      parent: this
    }
  },
  components: {
    ImageModal,
    Distpicker,
  },

  data() {
    return {
      sku,
      skuList:[{}],
      columns,
      columnsF:[...columns],
      labelCol: { span: 5 },
      wrapperCol: { span: 18 },
      ModalTitle: '新增商品',
      visible: false,
      loading: false,
      confirmLoading: false,
      formData,
      form: this.$form.createForm(this),
      seleteItems: {
        productCategorys: [],
        productSpecs: [],
        types: C_ITEMS.studioType
      },

      specs: [],
      
      imageUrl: '',
      imageUrls: [],
      imageRoot: url.imageRoot,
      uploadParams: {},
      imagesVisible: false,
      imageKey: '',
      product_desc: '',
      editor: {},
      distData: {},
      mapValue: {},
      mapData: {},

      detail: {},
    }
  },

  async created() {
    this.visible = true
    if (this.item) {
      this.loading = true
    }
    await this.$nextTick()
    await this.getProductCategory()
    await this.getProductSpec()
    if (this.item) {
      this.ModalTitle = '编辑商品'
      let item = await this.getDetail(this.item.product_id)
      this.detail = item
      let formFields = {}
      for (let d of formData) {
        formFields[d.name] = item[d.name]
      }
      this.product_desc = item['product_desc']
      this.imageUrl = item['product_image']
      this.imageUrls = item['product_images']
      // this.mapValue = {
      //   address: item['address'],
      //   lng: item['lng'],
      //   lat: item['lat'],
      // }
      if (item.product_spec.length > 0) {
        formFields.spec = item.product_spec[0].spec_name
        this.specs = item.product_spec[0].spec_dets
      }
      this.form.setFieldsValue(formFields)
      this.loading = false
    }
  },

  beforeDestroy() {
    this.visible = false
  },
  methods: {
    async getProductCategory() {
      let res = await this.$store.dispatch('storeProductCategoryAction', {})
      this.seleteItems.productCategorys = res.items
    },
    async getProductSpec() {
      let res = await this.$store.dispatch('storeProductSpecAction', {})
      this.seleteItems.productSpecs = res.items
    },
    async changeSpec(spec_id) {
      let res = await this.$store.dispatch('storeProductSpecDetailAction', {data: {spec_id}})
      this.specs = res.data.spec_dets
    },
    async getDetail(product_id) {
      let res = await this.$store.dispatch('storeProductDetailAction', {data: {product_id}})
      return res.data
    },
    changeEditor(key, value) {
      this.editor[key] = value
    },
    changeDist(data) {
      this.distData = {
        province: data[0].name,
        city: data[1].name,
        district: data[2].name
      }
    },
    changeMap(data) {
      console.log(data)
      this.mapData = data
    },
    async deleteItem(item, index) {
      if (item.det_id) {
        let res = await this.$store.dispatch('storeProductSpecDeleteDetAction', { data: { det_id: item.det_id } })
        this.specs.splice(index, 1)
        return 
      } 
      this.specs.splice(index, 1)
    },
    addItem() {
      this.specs.push({})
    },
    async handleOk() {
      this.confirmLoading=true;
      try {
        const { item, form, specs } = this
        let params = await form.validateFields()
        if (params) {
          if (item) {
            params.product_id = item.product_id
          }
          params.product_image = this.imageUrl
          params.product_images = this.imageUrls 
          params.product_desc = this.editor.product_desc
          if (params.spec && specs.length > 0) {
            let arr = []
            for (let spec of specs) {
              arr.push({
                det_id: spec.det_id || '',
                det_name: spec.det_name,
                market_price: spec.market_price,
                sale_price: spec.sale_price,
                instock: spec.instock ,
              })
            }
            let spec_id = ''
            if (this.detail.product_spec && this.detail.product_spec.length > 0) {
              spec_id = this.detail.product_spec[0].spec_id || ''
            }
            params.product_spec = [
              {
                spec_id,
                spec_name: params.spec,
                spec_dets: arr
              }
            ]
          }

          let res = await this.$store.dispatch(item ? 'storeProductUpdateAction' : 'storeProductAddAction', { data: params })
          if(res) {
            form.resetFields()
            this.parent.hideEditModal(1)
          }
        }
      } catch (err) {
        console.log(err)
      }
			this.confirmLoading=false;
    },
    handleCancel() {
      this.parent.hideEditModal(0)
    },
    
    closeImg(e) {
      e.stopPropagation()
      this.imageUrl = ''
    },
    
    closeImg2(e, index) {
      e.stopPropagation()
      this.imageUrls.splice(index, 1)
    },

    showImagesModal(imageKey) {
      this.imageKey = imageKey
      this.imagesVisible = true
    },
    hideImagesModal(image) {
      if (image) {
        if (this.imageKey === 'product_images') {
          this.imageUrls.push(image.path)
        } else {
          this.imageUrl = image.path
        }
      }
      this.imagesVisible = false
    },
    handleSkuSelect(e,node,item,index){
      let num = node.data.key
      if(this.skuList[index].id){
        let numF = this.skuList[index].index
        this.sku[numF].disabled = false
        this.skuList[index] = this.sku[num]
        this.sku[num].disabled = true
      }else{
        this.skuList[index] = this.sku[num]
        this.sku[num].disabled = true
      }
      let arr = []
      this.skuList.forEach(item=>{
        let obj = { title: item.name, dataIndex: item.label, key: item.label}
        arr.push(obj)
      })
      this.columnsF = [...arr,...this.columns]
    },
    addSku(){
      this.skuList.push({})
    },
    handleSkuValue(e,arr,index,label){
      arr[index][label] = e.target.value
    },
    handleSkuBlur(){
      let skuList = [...this.skuList]
      let arr = []
      skuList.forEach((item,index)=>{
        if(item.arr){
          arr[index] = []
          item.arr.forEach(it=>{
            if(it[item.label]){
              arr[index].push(it)
            }
          })
        }
      })
      let newArr = this.doCombination(arr)
      let specs = []
      newArr.forEach((item,index)=>{
        let obj = {}
        item.forEach(it=>{
          obj = {...obj,...it}
        })
        obj.det_id = -(index + 1)
        specs.push(obj)
      })
      this.specs = specs
    },
    delSku(item,index){
      if(item.index){
        let num = item.index
        this.sku[num].disabled = false
        this.skuList.splice(index,1)
        let arr = []
        this.skuList.forEach(item=>{
          let obj = { title: item.name, dataIndex: item.label, key: item.label}
          arr.push(obj)
        })
        this.columnsF = [...arr,...this.columns]
        this.handleSkuBlur()
      }else{
        this.skuList.splice(index,1)
      }
      
    },
    addSkuValue(item){
      if(!item.id){
        this.$message.warning('请先选择规格名!!')
        return false
      }
      console.log(item)
      item.arr.push({[item.label]:''})
    },
    delSkuValue(arr,index){
      arr.splice(index,1)
      this.handleSkuBlur()
    },
    //数组排列组合
    doCombination(arr) {
        var count = arr.length - 1 //数组长度(从0开始)
        var tmp = []
        var totalArr = []// 总数组
        return doCombinationCallback(arr, 0)//从第一个开始
        //js 没有静态数据，为了避免和外部数据混淆，需要使用闭包的形式
        function doCombinationCallback(arr, curr_index) {
          for( let val of arr[curr_index]) {
            tmp[curr_index] = val//以curr_index为索引，加入数组
            //当前循环下标小于数组总长度，则需要继续调用方法
            if(curr_index < count) {
              doCombinationCallback(arr, curr_index + 1)//继续调用
            }else{
              totalArr.push(tmp)//(直接给push进去，push进去的不是值，而是值的地址)
            }
            //js  对象都是 地址引用(引用关系)，每次都需要重新初始化，否则 totalArr的数据都会是最后一次的 tmp 数据；
            var oldTmp = tmp
            tmp = []
            for(let index of oldTmp) {
              tmp.push(index)
            }
          }
          return totalArr
        }
    }
  }
}
</script>
<style lang="scss" scoped>
  .sku-warp{
    background: #f8f8f8;
    border-radius: 2px;
    position: relative;
  }
  .sku-del{
    position: absolute;
    top: 0px;
    right: 10px;
  }
</style>
