<template>
  <div>
    <a-breadcrumb class="breadcrumb">
      <a-breadcrumb-item>首页</a-breadcrumb-item>
      <a-breadcrumb-item>市场应用</a-breadcrumb-item>
      <a-breadcrumb-item>微信小店</a-breadcrumb-item>
    </a-breadcrumb>
    <div class="main-body students">
      <a-tabs :animated="false" :activeKey="activeKey" @change="changeTab">
        
        <!-- <a-tab-pane key="8">
          <span slot="tab">商品规格<a-badge :count="0" /></span>
          <spgg v-if="activeKey==='8'"/>
        </a-tab-pane> -->
        <a-tab-pane key="1">
          <span slot="tab">商品管理<a-badge :count="0" /></span>
          <spgl v-if="activeKey==='1'"/>
        </a-tab-pane>
        <a-tab-pane key="2">
          <span slot="tab">商品分类<a-badge :count="0" /></span>
          <spfl v-if="activeKey==='2'"/>
        </a-tab-pane>
        <a-tab-pane key="3">
          <span slot="tab">订单管理<a-badge :count="0" /></span>
          <ddgl v-if="activeKey==='3'"/>
        </a-tab-pane>
        <a-tab-pane key="4">
          <span slot="tab">退款审核<a-badge :count="0" /></span>
          <tksh v-if="activeKey==='4'"/>
        </a-tab-pane>
        <!-- <a-tab-pane key="5">
          <span slot="tab">红人管理<a-badge :count="0" /></span>
          <bktj v-if="activeKey==='5'"/>
        </a-tab-pane> -->
        <a-tab-pane key="6">
          <span slot="tab">订单评价<a-badge :count="0" /></span>
          <ddpj v-if="activeKey==='6'"/>
        </a-tab-pane>

        <a-tab-pane key="8">
          <span slot="tab">积分兑换<a-badge :count="0" /></span>
          <credit v-if="activeKey==='8'"/>
        </a-tab-pane>

        <a-tab-pane key="9">
          <span slot="tab">供应商管理<a-badge :count="0" /></span>
          <suppliers v-if="activeKey==='9'"/>
        </a-tab-pane>

        <a-tab-pane key="10">
          <span slot="tab">采购记录<a-badge :count="0" /></span>
          <suppliersStock v-if="activeKey==='10'"/>
        </a-tab-pane>

        <!-- <a-tab-pane key="7">
          <span slot="tab">商品优惠券<a-badge :count="0" /></span>
          <spyhq v-if="activeKey==='7'"/>
        </a-tab-pane> -->
      </a-tabs>
    </div>
  </div>
</template>

<script>
import spgl from './spgl/index'
import spfl from './spfl/index'
import suppliers from './suppliers/index'
import suppliersStock from './stock/index'
import ddgl from './ddgl/index'
import tksh from './tksh/index'
import ddpj from './ddpj/index'
import spyhq from './spyhq/index'
import spgg from './spgg/index'
import credit from './credit'

export default {
  components: {
    spgl,
    spfl,
    suppliers,
    suppliersStock,
    ddgl,
    tksh,
    ddpj,
    spyhq,
    spgg,
    credit
  },
  data() {
    return {
      activeKey: '1'
    }
  },

  methods: {
    changeTab(key) {
      this.activeKey = key
    }
  }
}
</script>

